<template>
  <div id="paypal-button-container"></div>
</template>

<script>
export default {
  data () {
    return {
      amount: 299
    }
  },
  mounted () {
    const script = document.createElement("script")
    script.src = "https://www.paypal.com/sdk/js?client-id=ASfLzTfm4tCxhCnQM0JWkxaXCqcWENKTZ2Z8uFj6cBkKGdZtMphhnLDWryhW9B_nMqwwaUBGCnMj_K1o"
    script.addEventListener("load", this.setLoaded)
    document.body.appendChild(script)
    paypal.Buttons().render('#paypal-button-container');
  },
  methods: {
    setLoaded () {
      this.loaded = true
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: 'This is a test',
                  amount: {
                    currency_code: "PHP",
                    value: this.amount
                  }
                }
              ]
            })
          }
        })
    }
  }
}
</script>

<style>

</style>