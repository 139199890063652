<template>
  <v-layout row wrap justify-center class="mr-0 mt-5">
    <v-flex xs6>
      <v-card class="pa-4">
        <p>
          Take a photo of the remittance receipt or deposit slip, or get a screenshot of the confirmation message 
          if payment is made online. Upload here for verification.
        </p>

        <v-tabs
          v-model="tab"
          background-color="transparent"
          color="primary"
          
          grow
        >
          <v-tab class="text-capitalize">Remittance Centers</v-tab>
          <v-tab class="text-capitalize">Banks</v-tab>

        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card color="white" class="pa-4" flat>
              <v-row align="center" justify="center" no-gutters>
                <v-col v-for="(center, i) in remittanceCenters" :key="i" cols="4" class="pa-4 text-center">
                  <v-img :src="center.imageUrl"></v-img>
                </v-col>
              </v-row>

              <v-row align="center" justify="center" no-gutters>
                <v-col cols="6" class="text-center">
                  <span>{{ remittancePersonel.name }}</span><br />
                  <span class="grey--text">Name</span>
                </v-col>

                <v-col cols="6" class="text-center">
                  <span>{{ remittancePersonel.contactNum }}</span><br />
                  <span class="grey--text">Contact Number</span>
                </v-col>
              </v-row>
              
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-row align="center" justify="center" class="pa-4" no-gutters>
              <v-col v-for="(bank, i) in banks" :key="i" cols="4">
                <v-card color="white" flat>
                  <v-row align="center" justify="center" no-gutters>
                    <v-col cols="12">
                      <v-img :src="bank.imageUrl"></v-img>
                    </v-col>

                    <v-col cols="12" class="text-center py-2">
                      <span class="primary--text">{{ bank.name }}</span>
                    </v-col>

                    <v-col cols="12" class="text-center py-2">
                      <span>{{ bank.accountNumber }}</span><br />
                      <span class="caption grey--text">Account Number</span>
                    </v-col>

                    <v-col cols="12" class="text-center py-2">
                      <span>{{ bank.accountName }}</span><br />
                      <span class="caption grey--text">Account Name</span>
                    </v-col>
                  </v-row>
                  
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import InfoService from '@/services/InfoService'

export default {
  data () {
    return {
      remittancePersonel: {},
      remittanceCenters: [],
      banks: [],
      tab: null
    }
  },
  methods: {
    getPaymentMethods () {
      InfoService.getPaymentMethods()
        .then(res => {
          this.remittancePersonel = res.data.personel
          this.remittanceCenters = res.data.remittanceCenters
          this.banks = res.data.banks
        })
    }
  },
  created () {
    this.getPaymentMethods()
  }
}
</script>
