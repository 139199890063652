<template>
  <v-dialog persistent v-model="show" width="700px">
    <v-sheet>
      <v-tabs
        dark
        grow
        icons-and-text
        color="primary"
      >
        <v-tabs-slider color="white"></v-tabs-slider>

        <v-tab href="#tab-1" @click="currentTab = 1, remitted = false, useFunds = false, resetAddCreditForm()">
          Pay Later
        </v-tab>

        <v-tab href="#tab-2" @click="currentTab = 2, remitted = true, useFunds = false">
          Pay Now
        </v-tab>

        <v-tab href="#tab-3" @click="currentTab = 3, remitted = false, useFunds = true, resetAddCreditForm()">
          Use Funds
        </v-tab>

        <v-tab href="#tab-4" @click="currentTab = 4, remitted = false, usePaypal = true">
          Use PayPal
        </v-tab>

        <v-tab-item
          v-for="i in 4"
          :key="i"
          :value="'tab-' + i"
        >
          <div v-if="i == 1">
            <v-layout row wrap align-center>
              <v-flex xs12 class="px-4 pt-3">
                <p>You will need to send your money to one of payment methods:
                  <span class="font-weight-bold">Palawan Express Padala, Cebuana, Mlhuillier, LBC, and our Bank Partners</span>
                </p>
                <p>You can take a picture and upload the remittance later in the table by clicking the <v-icon color="primary" class="grey lighten-3">more_vert</v-icon> button in the Transaction Page. You can email, text or call us within working hours so that we can verify and reflect the amount that you deposited faster.</p>
              </v-flex>
            </v-layout>
          </div>
          <div v-if="i == 2">
            <v-layout row wrap align-center>
              <v-flex xs12 class="px-4 pt-3">
                <v-form ref="addCreditsform">
                  <input
                    ref="image"
                    type="file"
                    name="image"
                    accept="image/*"
                    style="display: none;"
                    @change="setImage"
                  />
                  <VueCropper
                    :dragMode="'none'"
                    :viewMode="1"
                    :autoCrop="false"
                    :zoomOnWheel="false"
                    :background="false"
                    :src="imgSrc"
                    v-show="imgSrc"
                    ref="cropper"
                  />
                  <v-layout row wrap>
                    <p class="px-2">Upload your money remittance or bank deposit receipt so that we can confirm your payment. Expect to reflect your credt in a few hours within working days.</p>
                    <v-flex xs12>
                      <v-text-field
                        outline
                        readonly
                        :label="imgSrc ? 'Click here to replace the image' : 'Click here to upload the image'"
                        append-icon='attach_file'
                        v-model='fileName'
                        :rules="imageRule"
                        @click='launchFilePicker'
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-flex>
            </v-layout>
          </div>
          <div v-if="i == 3">
            <v-layout row wrap justify-center class="py-5">
              <v-flex xs5 text-xs-center>
                <p :class="{
                  'display-2 success--text mb-1' : wallet > amount && $vuetify.breakpoint.mdAndUp,
                  'display-2 grey--text mb-0' : wallet < amount && $vuetify.breakpoint.mdAndUp,
                  'headline success--text mb-1' : wallet > amount && $vuetify.breakpoint.smAndDown,
                  'headline grey--text mb-0' : wallet < amount && $vuetify.breakpoint.smAndDown
                }">{{ wallet | phCurrency }}</p>
                <p :class="{
                  'title grey--text mb-0' : $vuetify.breakpoint.mdAndUp,
                  'subtitle grey--text mb-0 font-weight-bold' : $vuetify.breakpoint.smAndDown
                  }">Available Funds</p>
              </v-flex>
              <v-flex xs1 text-xs-center>
                <v-divider vertical></v-divider>
              </v-flex>
              <v-flex xs5 text-xs-center>
                <p :class="{
                  'display-2 grey--text mb-1' : $vuetify.breakpoint.mdAndUp,
                  'headline grey--text mb-1' : $vuetify.breakpoint.smAndDown,
                }">{{ amount | phCurrency }}</p>
                <p :class="{
                  'title grey--text mb-0' : $vuetify.breakpoint.mdAndUp,
                  'subtitle grey--text mb-0 font-weight-bold' : $vuetify.breakpoint.smAndDown
                }">Payment</p>
              </v-flex>
            </v-layout>
          </div>
          <div v-if="i == 4">
            <Paypal />
          </div>
        </v-tab-item>
      </v-tabs>
      <v-flex xs12>
        <v-card-actions class="lightBlack px-4 py-3">
          <v-btn
            large
            text
            @click="show = false, resetAddCreditForm()"
          >Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            large
            color="primary"
            label=""
            v-if="!useFunds && !usePaypal"
            @click="uploadReceipt"
            :loading="isUploading"
          >Add Credits</v-btn>
          <v-btn
            depressed
            large
            color="success"
            v-if="useFunds & wallet > amount && !usePaypal"
            @click="buyCreditWithFunds()"
          >Use Funds</v-btn>
          <v-btn
            depressed
            large
            disabled
            v-if="useFunds & wallet < amount && !usePaypal"
          >Use Funds</v-btn>
        </v-card-actions>
      </v-flex>
    </v-sheet>
  </v-dialog>
</template>

<script>
import io from 'socket.io-client'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import accountingMixin from '@/mixins/accountingMixins'
import Paypal from '@/components/PayPalComponents/Index'

export default {
  components: {
    VueCropper,
    Paypal
  },
  mixins: [
    accountingMixin
  ],
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    creditPricingId: {
      type: String,
      required: true
    },
    amount: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      socket: io(process.env.VUE_APP_SERVER),
      file: '',
      usePaypal: false,
      useFunds: false,
      imgSrc: '',
      fileName: '',
      isUploading: false,
      remitted: false,
      type: 'Add Credits',
      count: 1,
      imageRule: [
        (v) => !!v || 'Please enter an image.'
      ]
    }
  },
  methods: {
    launchFilePicker () {
      this.$refs.image[0].click()
    },
    setImage (e) {
      if (e.target.files[0]) {
        this.file = e.target.files[0]
      }

      if (this.file.type.indexOf('image/') === -1) {
        alert('Please select an image file')
        this.imgSrc = ''
        return
      }

      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
          this.imgSrc = event.target.result
          this.fileName = e.target.files[0].name
          this.$refs.cropper[0].replace(event.target.result)
        }
        reader.readAsDataURL(this.file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    uploadReceipt () {
      if (this.remitted && this.$refs.addCreditsform[0].validate()) {
        this.isUploading = true
        if (!HTMLCanvasElement.prototype.toBlob) {
          Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
            value: function (callback, type, quality) {
              let canvas = this
              let binStr = atob(canvas.toDataURL(type, quality).split(',')[1])
              let len = binStr.length
              let arr = new Uint8Array(len)

              for (let i = 0; i < len; i++) {
                arr[i] = binStr.charCodeAt(i)
              }
              callback(new Blob([arr], { type: type || 'image/png' }))
            }
          })
        }

        this.$refs.cropper[0].getCroppedCanvas().toBlob(async (blob) => {
          const formData = new FormData()
          formData.append('receipt', blob)
          formData.append('amount', this.amount)
          formData.append('creditPricing', this.creditPricingId)

          this.$store.dispatch('transactions/BUY_CREDITS_WITH_REMITTANCE', formData)
            .then(res => {
              this.socket.emit('addcredits', {
                type: this.type,
                count: this.count
              })
              this.imgSrc = ''
              this.file = ''
              this.success()
              this.$swal({
                type: 'success',
                text: 'Succesfully purchased a credit pack, please wait for a few hours for our admins to verify the receipt and reflect the credits to your account.',
                confirmButtonColor: '#5F27CD',
                onOpen: () => { document.activeElement.blur() }
              })
            })
            .catch(() => {
              this.$swal({
                type: 'error',
                text: 'There is something wrong in the server!'
              })
            })
            .finally(() => {
              this.isUploading = false
            })
        }, this.file.type, 0.3)
      } else {
        this.isUploading = true
        let transaction = {
          user: this.$store.getters['user/user'].user._id,
          creditPricing: this.creditPricingId,
          amount: this.amount,
          type: 'Add Credits',
          status: 'Pending'
        }
        this.$store.dispatch('transactions/BUY_CREDITS', transaction)
          .then(res => {
            this.success()
            this.$swal({
              type: 'success',
              text: 'Succesfully purchased a credit pack, We will wait for the money remittance or bank deposit receipt within 2 days.',
              confirmButtonColor: '#5F27CD',
              onOpen: () => { document.activeElement.blur() }
            })
          })
          .catch(error => {
            this.$swal({
              type: 'error',
              title: 'There is something wrong in the server!',
              text: error.response
            })
          })
          .finally(() => {
            this.isUploading = false
          })
      }
    },
    buyCreditWithFunds () {
      this.$swal.queue([{
        title: 'Are you sure?',
        text: "You will not revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1CD1A1',
        cancelButtonColor: '#8388A5',
        confirmButtonText: 'Confirm',
        showLoaderOnConfirm: true,
        onOpen: () => { document.activeElement.blur() },
        preConfirm: () => {
          let transaction = {
            amount: this.amount,
            creditPricingId: this.creditPricingId
          }
          return this.$store.dispatch('transactions/BUY_CREDITS_WITH_FUNDS', transaction)
            .then(res => {
              this.$swal.insertQueueStep({
                type: 'success',
                text: 'Succesfully purchased credit pack',
                confirmButtonColor: this.$vuetify.theme.primary
              })
              this.$emit('close')
            })
            .catch(() => {
              this.$swal.insertQueueStep({
                type: 'error',
                text: 'There is something wrong in the server'
              })
            })
        }
      }])
    },
    success () {
      this.show = false
      if (this.remitted) {
        this.$emit('close')
        this.$refs.addCreditsform[0].reset()
      } else {
        this.$emit('close')
      }
    },
    resetAddCreditForm () {
      this.$refs.addCreditsform[0].reset()
      this.$refs.image[0].value = ''
      this.imgSrc = ''
      this.fileName = ''
      this.file = ''
    }
  },
  computed: {
    wallet () {
      return this.$store.getters['transactions/wallet']
    },
    show: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.fund = {}
          this.$emit('close')
        }
      }
    }
  }
}
</script>

<style>

</style>
