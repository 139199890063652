import Api from '@/services/Api'

export default {
  getCreditPricings () {
    return Api().get('/api/credit-pricings/a')
  },
  getVisiblePricings () {
    return Api().get('api/admin/credits/visible-credit-pricing')
  }
}
