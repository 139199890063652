<template>
  <div>
    <v-layout v-if="!$vuetify.breakpoint.mdAndDown" pa-4 mx-3 row wrap text-xs-center justify-center>
      <v-flex xs7>
        <v-sheet class="px-4">
          <v-select
            outline
            hide-details
            color="primary"
            label="Select Package"
            item-value="price"
            :item-text="price"
            :items="pricings"
          >
            <template slot="item" slot-scope="data">
              <v-list-tile-content @click="selected = data.item">
                <v-list-tile-title v-html="data.item.price.toLocaleString('en-PH', { style: 'currency', currency: 'PHP' })"/>
              </v-list-tile-content>
            </template>
          </v-select>
          <div v-if="selected" class="my-4">
            <p class="title">{{ selected.creditQuantity }} Credits</p>
            <p v-if="selected.hasLawyerListing" class="title">Lawyers Listing</p>
          </div>
          <v-btn large depressed block :disabled="!selected" color="primary" @click="buyCredits()">Top Up</v-btn>
        </v-sheet>
      </v-flex>
      <v-flex xs5 pl-2 text-xs-left>
        <span>Available Credits</span>
        <p class="display-1">{{ userCredits }}</p>
        <div v-if="selected">
          <span>Your New Credits Balance</span>
          <p class="display-1">{{ userCredits + selected.creditQuantity }}</p>
        </div>
      </v-flex>
    </v-layout>
    <v-layout v-else row wrap xs12 pa-4 :mx-3="!$vuetify.breakpoint.mdAndDown">
      <v-flex xs12>
        <v-select
          outline
          hide-details
          color="primary"
          label="Select Package"
          item-value="price"
          :item-text="price"
          :items="pricings"
        >
          <template slot="item" slot-scope="data">
            <v-list-tile-content @click="selected = data.item">
              <v-list-tile-title v-html="data.item.price.toLocaleString('en-PH', { style: 'currency', currency: 'PHP' })"/>
            </v-list-tile-content>
          </template>
        </v-select>
      </v-flex>
      <v-flex v-if="selected" xs12 text-xs-center pt-4>
        <v-sheet class="dropShadow ">
          <div class="px-4 pt-4">
            <p class="title font-weight-medium primary--text" style="color: #383838;">{{ selected.creditQuantity }} Credits</p>
            <p class="title font-weight-medium primary--text" style="color: #383838;" v-if="selected.hasLawyerListing">Lawyer List</p>
            <p class="display-1 font-weight-medium" style="color: #383838;">₱{{ selected.price }}</p>
          </div>
          <v-btn depressed block large color="primary" @click="topUpDialog = true">Top Up!</v-btn>
        </v-sheet>
      </v-flex>
    </v-layout>
    <v-flex pb-2 :mx-3="!$vuetify.breakpoint.mdAndDown" :class="!$vuetify.breakpoint.mdAndDown ? 'px-4' : 'px-1'">
      <CreditsDialog />
      <LawyerListingDialog />
    </v-flex>
    <TopUp :visible="topUpDialog" :creditPricingId="selected._id || ''" :amount="selected.price || 0" @close="topUpDialog = false"/>
  </div>
</template>

<script>
import TopUp from '@/components/TopUpComponents/TopUpDialog'
import CreditsDialog from '@/components/TopUpComponents/CreditsDialog'
import LawyerListingDialog from '@/components/TopUpComponents/LawyerListingDialog'
import CreditPricingService from '@/services/CreditPricingService'
import AccountingMixins from '@/mixins/accountingMixins'

export default {
  components: {
    CreditsDialog,
    LawyerListingDialog,
    TopUp
  },
  mixins: [
    AccountingMixins
  ],
  data () {
    return {
      topUpDialog: false,
      creditPricingId: '',
      amount: 0,
      withReceipt: '',
      selected: '',
      pricings: []
    }
  },
  methods: {
    // Experiment this
    buyCredits () {
      this.topUpDialog = true
    },
    price: item => item.price.toLocaleString('en-PH', { style: 'currency', currency: 'PHP' }),
    getVisibleCreditPricings () {
      CreditPricingService.getVisiblePricings()
        .then(res => {
          this.pricings = res.data
        })
        .catch(() => {
          this.$swal({
            type: 'error',
            text: 'Something went wrong when fetching the credit packs!',
            confirmButtonColor: this.$vuetify.theme.primary,
            onOpen: () => { document.activeElement.blur() }
          })
        })
    }
  },
  computed: {
    userCredits () {
      return this.$store.getters['user/userCredits']
    }
  },
  created () {
    this.getVisibleCreditPricings()
    return this.$store.dispatch('transactions/GET_TRANSACTIONS')
  }
}
</script>
