<template>
  <v-dialog
    persistent
    v-model="show"
    width="400"
  >
    <template v-slot:activator="{ on }">
      <v-btn text v-on="on">
        Learn more about <span class="font-weight-bold pl-1 primary--text">Credits</span>
      </v-btn>
    </template>
    <v-card class="px-4 pt-4 pb-2">
      <p class="title primary--text text-xs-center">About Credits</p>
      <p class="subheading">
        You will use credits if you create a proposal for a job posted by the clients. It will only cost one credit per proposal/bid.
        You can get one credit if you give a pro bono and only if the project is complete.
      </p>
      <v-btn depressed block large color="primary" @click="show = false">OK</v-btn>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  data () {
    return {
      show: false
    }
  }
}
</script>
