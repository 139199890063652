<template>
  <v-dialog
    persistent
    v-model="show"
    width="500"
  >
    <template v-slot:activator="{ on }">
      <v-btn text v-on="on">
        Learn more about <span class="font-weight-bold pl-1 primary--text">Lawyers List</span>
      </v-btn>
    </template>
    <v-card class="px-4 pt-4 pb-2">
      <p class="title primary--text text-xs-center">About Lawyers List</p>
      <p class="subheading">
        Lawyers List can help you achieve an internet presence. Clients can easily identify what are your legal practices,
        they can also view your ratings which can help you get more clients.
        <br/><br/>
        You can subscribe to the Lawyers List for one month if you choose the credit packs that has Lawyers Listing on it.
        <br/><br/>
        <span class="primary--text font-weight-bold">Note:</span> If you buy a credit pack that has "Lawyers Listing" and verified by us today, you will be on the Lawyers List until next month,
        and if you buy another credit pack that has "Lawyers Listing" tomorrow, your subscription will be adjusted that date's subsequent month.
        <br/><br/>
        <span class="primary--text font-weight-bold">Example:</span> On November 11 you bought a credit pack that has "Lawyers Listing" which ends on December 11 but then, on November 20 you bought another credit pack that has "Lawyers Listing", thus the subscription will adjust and ends on December 20.
      </p>
      <v-btn depressed block large color="primary" @click="show = false">OK</v-btn>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  data () {
    return {
      show: false
    }
  }
}
</script>
