import Api from '@/services/Api'

export default {
  getPaymentMethods () {
    return Api().get('/api/information/payment-methods')
  },
  getRemittanceCenters () {
    return Api().get('/api/admin/paymethods/remittanceCenters')
  },
  getWithdrawals () {
    return Api().get('/api/information/withdrawals')
  }
}
