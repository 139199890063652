<template>
  <v-layout justify-center :my-5="!$vuetify.breakpoint.mdAndDown">
    <v-flex xs12 sm12 md12 lg8 xl6>
      <v-sheet class="dropShadow">
        <TopUpSelector />
      </v-sheet>
      <PaymentMethods />
    </v-flex>
  </v-layout>
</template>

<script>
import TopUpSelector from '@/components/TopUpComponents/TopUpSelector'
import PaymentMethods from '@/components/IndexComponents/PaymentMethods'

export default {
  components: {
    TopUpSelector,
    PaymentMethods
  }
  // methods: {
  //   buyCredits (creditPricingId, amount) {
  //     this.creditPricingId = creditPricingId
  //     this.amount = amount
  //     this.topUpDialog = true
  //   },
  //   getVisibleCreditPricings () {
  //     CreditPricingService.getVisiblePricings()
  //       .then(res => {
  //         this.pricings = res.data
  //       })
  //   }
  // },
  // created () {
  //   this.getVisibleCreditPricings()
  //   return this.$store.dispatch('transactions/GET_TRANSACTIONS')
  // }
}
</script>
